import React from "react";
import { StaticImage  } from "gatsby-plugin-image"

import LayoutSub from "../components/layoutSub";
import SEO from "../components/seo";
import ExLink from "../components/exLink";


function Profile({ location, pageContext }) {

  const { breadcrumb: { crumbs } } = pageContext;

  const {pathname} = location;
  const pagetitle = "施術者プロフィール";
  const description = "西荻窪・葛西の整体院・ぺんぎん堂のプロフィールです。基礎医学からオステオパシーをしっかり学んだ施術者がお待ちしております。";

  const { state = {} } = location;
  const pathTop = (state) ? state.pathTop : "/";

  return (
    <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop}>
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
      />

      <section>
      <h1 className=" my-2 heading-b">こんにちは</h1>
      <p>ご訪問いただき、ありがとうございます。</p>
      <p>オステオパシーという方法で、手を使って身体の調整をしております ”ぺんぎん堂” の橋本智子です。</p>

        <div className="overflow-hidden mb-6" >
          <StaticImage
            src="../images/profile.jpg"
            alt="西荻窪の整体ぺんぎん堂"
            placeholder="blurred"
            layout="constrained"
            width={260}
            className="max-w-2/5 xs:max-w-10 float-left mr-5 my-3 ml-2"
          />


          <p>「オステオパシー」という方法は、全身をチェックして原因を探してから施術するので、腰痛や肩こりといった筋肉や関節の不調に加えて、</p>
          <ul>
            <li>・&nbsp;疲れが抜けない</li>
            <li>・&nbsp;生理痛がひどい</li>
            <li>・&nbsp;足がむくむ</li>
          </ul>
          <p>など、様々な不調の調整させていただいております。</p>

        </div>
        
        <h2 className="heading-l">オステオパシーを始めたきっかけ</h2>
        <div className="overflow-hidden" >
        <StaticImage
          src="../images/bear-mother.png"
          alt="くまのお母さん"
          placeholder="blurred"
          layout="constrained"
          width={200}
          className="max-w-2/5 float-left mr-5 my-3 ml-2"
        />

<p className="text-lg">ボディーワークを受ける</p>  

<p>小さいころから猫背で、小学校では側弯の傾向があると言われて、鉄棒にぶらさがってなんとかしようとしていたくらい姿勢が悪い子供でした。背が高くて、幼稚園の時は熊のお母さん役しかもらえず、小さくて可愛い子がとってもうらやましかったので、自然と背中が丸くする癖がついてしまったのかも？！しれません。</p><p>

姿勢は悪いままだったものの、幸い、側弯症にはならず、腰や背中に痛みがでるということもなく成長しました。とにかくこの悪い姿勢をなんとかしたいと、ボディーワーク（ロルフィング）を受けたのが、私の身体への探求心の種となりました。</p>

</div>

<p className="text-lg">素人として、身体の探求</p>
<p>身体の事をもっと知りたいと思うようになってからは、一般の人向けの筋膜リリースや、クレニオワーク（頭へのアプローチ）、内臓マニピュレーション、操体法など、身体を整えるいろいろなジャンルのセミナーに参加しました。会社員として働きながら、セミナーに参加して、家族や友達相手にやってみるということを数年続けました。 </p> 

<div className="overflow-hidden" >
        <StaticImage
          src="../images/seed-leaf.png"
          alt="体の探求の芽"
          placeholder="blurred"
          layout="constrained"
          width={200}
          className="max-w-2/5 float-right mr-5 my-3 ml-2"
        />

<p>単発のセミナーに出てもなかなか上達しないから、長期のセミナーで勉強しよう！と思いたち、整体教室に入学し、1年間毎週金曜日の夜に会社帰りに通いました。単発のセミナーとは違ってプロを養成するための教室でしたが、1年終わってみて、何か足りないという思いを捨てきれず…  </p>

<p>勉強した整体で、家族の腰痛や浅い眠りが改善することはありました。でも、自分が何をしているのか、相手の身体の何が反応してくれたのかをぼんやりとしか理解できず、もっと知りたい、そうしたらもっと身体に変化が起きるのでは？という思いで頭がいっぱいになりました。1年整体を勉強したら、ますますもっと学びたくなっていました。</p>
</div>

<p className="text-lg">決断</p>

<p>全日制の学校に入って、基礎から始めて本格的に勉強したい。でも、今までやってきた仕事も好きな部分がたくさんあるし（ストレスもいっぱいありましたが(^^;)）、若い人に混じって勉強についていけるか（クラスの最年少は23歳でした）、など、かなり悩みました。</p>  

<div className="overflow-hidden mb-6" >
        <StaticImage
          src="../images/decision.png"
          alt="オステオパシーを学ぶ決断"
          placeholder="blurred"
          layout="constrained"
          width={200}
          className="max-w-2/5 float-left mr-5 my-3 ml-2"
        />



<p>なかなか一歩を踏み出せずにいましたが、参加したセミナーでお会いしたセラピストさんに「いい手をしているから、向いてると思うよ」と言われたことや、「自分がやりたいと思った時が適齢期！」という友人の言葉に背中を押されました。</p>

<p>この二人のおかげで、今の私がいます。本当に感謝しています。</p>

<p>そんなわけで、やってみたい！という気持ちを抑えられなくなり、会社を退職して、ジャパン・カレッジ・オブ・オステオパシーに入学しました。  1年間のインターン期間を含めて3年をかけて学び、アメリカでの解剖実習や研修にも参加しました。</p>

<p>2020年からは、母校での内臓マニピュレーションの授業で講師アシスタントを、2023年からは「ジョーンズ ストレイン・カウンターストレイン」というオステオパシーのテクニックの講師をしております。回り道はしましたが、結果的にはとてもいい選択ができたと思っています。</p>

</div>

<h2 className="heading-l">なぜオステオパシーを選んだか</h2>

<p>
身体の勉強をしようと思ったきっかけは、自分がクライアントとしてうけたボディーワーク（ロルフィング）でした。ロルフィングでは、クライアントさん自身の参加がとても重要で、大きな要素です。</p>

<p>セッションを受けた時には、自分自身を観察して感じることが求められました。</p>

<p>年を経るにつれて、身体のあちこちに不調が出てきた母親の健康をサポートしたい、そう考えた時に…  </p>
<p>母が、そこまで集中して自分自身を観察し、セッションに参加するのは難しいのでは、と思いました。 </p> 

<p>健康な身体を保つためには、クライアントさん自身の意識はとても大切だと思います。ロルフィングのようなボディーワークは、私自身、大好きです。</p>

<p>ただ、”自分がどんな人に、どんなことをしたいか？”と考えた時に、身体の不調で自分自身をじっくり観察して意識する余裕のない人や、元々そういったことに集中するのが苦手な人のお手伝いもしたいな、と思い、オステオパシーを学ぼう！と決めました。</p>

<p>私の目指すオステオパシーの施術は、</p>

<ul className="list-disc">
<li>オステオパシーの手技でコリや不調をやわらげ、</li>
<li>そして、おひとりおひとりの状態に合わせて、ご自身を観察したり、自分の身体に意識を向けてもらうことも施術に取り入れる、</li>
<li>日常生活での簡単なアドバイスもお話しする</li>
</ul>

<p  className="mb-6">です。</p>  

<h2 className="heading-l">なぜオステオパシーを選んだか その２</h2>

<p>会社員時代に受けたセミナーの中で、一番のお気に入りは、「頭と仙骨」に触れる ”クレニオセイクラルワーク” でした。 </p> 

<p>”頭蓋へのアプローチって、そもそも誰がいつ始めたものなんだろう？”、と思って調べてみたところ、オステオパシーというものがその原点である、ということを知りました。 </p> 

<p>それまで聞いたこともなかった「オステオパシー」でしたが、本を読んだり、実際に施術を受けてみたりして、 </p> 

<ul className="list-disc">
<li>オステオパシーの目指す ”健康 ”の奥の深さや、</li>  
<li>手技の幅の広さ</li>
</ul>  

<p>に魅かれました。</p>
<p  className="mb-6">”一生勉強することになるんだろうな” と覚悟が必要でしたが、それでも、オステオパシーを学びたい！と思うようになりました。</p>

<h2 className="heading-l">学校で学んだこと</h2>

<p>ジャパン・カレッジ・オブ・オステオパシーでは、解剖学や生理学といった身体の基礎知識から最新の実技までを3年にわたってじっくり学び、1430時間のインターンも行いました。</p>

<p className="mb-6">また、アメリカのウェスト・バージニア医科大学にて解剖実習と、オステオパシー医師による研修にも参加しました。</p>

<hr className="topix-border" />

<p>「丁寧に、謙虚に、全力で！」が私の施術家としてのモットーです。クライアントさんのお身体とじっくり対話するようなイメージで施術をしております。</p>

<p>長年の痛みや不調にお悩みの場合、快方に向かうまで時間がかかることもあります。オステオパシーの施術でコリや不調を和らげ、日々の生活の中で気にしていただきたいことや身体の使い方などもお話しして、クライアントさんに寄り添う施術を提供していくことを心掛けております。</p>

<p className="mb-6">お身体にお困りのことがあったら、ぺんぎん堂にご相談ください(^^)/</p>


<div className="border-2 border-teal border-solid rounded bg-grey-50 p-2 mb-6">

          <p className="">橋本 智子&nbsp;&nbsp;MRO(J）*</p>
          <ul className="text-sm lg:text-base list-disc m-2">
            <li className="mb-1"><ExLink href="https://www.joa-jco.com">ジャパン カレッジ オブ オステオパシー</ExLink>ジョーンズ・ストレイン/カウンターストレイン基礎１&nbsp;講師</li>
            <li className="mb-1"><ExLink href="https://jones-scsaj.com/free/members">ジョーンズ・ストレイン/カウンターストレイン アカデミー</ExLink>理事<br />レベル &#8544;・&#8545; 認定試験合格</li>
            <li className="mb-1"><ExLink href="https://www.joa-jco.com">ジャパン カレッジ オブ オステオパシー</ExLink>（全日制３年 卒業）</li>
            <li className="mb-1"><ExLink href="https://www.wvsom.edu">アメリカ・ウエストバージニア・オステオパシー医科大学</ExLink>にて解剖実習修了</li>
            <li className="mb-1"><ExLink href="https://osteopathic.jp/">日本オステオパシー学会</ExLink>所属</li>

          </ul>
          <p className="text-sm lg:text-base ">
            *) MRO(J)とは、Member of Registered Osteopaths（japan）の略で、<ExLink href="http://osteopathy.gr.jp/mro/">日本オステオパシー連合</ExLink>が認定しているオステオパスの国際ライセンスです。</p>
          </div>

          <h2 className="heading-l">趣味</h2>

<p>
  パンを作るのも食べるのも大好きです。
  ５年ほど前にカリンでおこした自家製酵母を大切につないで、週に１回パンを焼いています。
  時々、”UFO出現？！”という謎の物体ができあがりますが（笑）、自分で焼いたパンは美味しいです。
</p>

<div className="flex flex-row">
  <div className="m-0.5">
<StaticImage
          src="../images/bread1.jpg"
          alt="自家製パン"
          placeholder="blurred"
          layout="constrained"
          width={250}
          className=""
        />
  </div>

  <div className="m-0.5">
  <StaticImage
          src="../images/bread2.jpg"
          alt="自家製パン"
          placeholder="blurred"
          layout="constrained"
          width={250}
          className=""
        />
  </div>
  </div>


<p>
  学生時代は、バドミントンに夢中でした。<br />
  小学校から始めて、中学は１年の途中から幽霊部員でしたが、高校・大学時代は毎日のように練習していました。<br />
  決して強くはなかったですが、バドミントンをするのは楽しかった！<br />
  今では自分でプレーすることは、ほとんどないですが、試合をみるのは大好きです。
    </p>




      </section>
    </LayoutSub>
      );
}

export default Profile;
